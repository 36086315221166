.search-column-checkbox {
    @apply relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem]
    appearance-none border border-solid border-ops-gunmetal/50 outline-none
    before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0
    before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent]
    before:content-['']   checked:before:opacity-[0.16]
    checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block
    checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem]
    checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white
    checked:after:bg-transparent checked:after:content-['']
      hover:cursor-pointer hover:before:scale-100 hover:before:opacity-[0.06] hover:before:shadow-[0px_0px_0px_9px_rgba(0,0,0,0.7)]
    focus:shadow-none focus:ring-0 focus:transition-[border-color_0.2s]
      focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_8px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s]
    focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:content-['']
      checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_8px_rgba(0,0,0,0.6)]
    checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px
    checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem]
    checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem]
    checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid
    checked:focus:after:border-white checked:focus:after:bg-transparent;
}

.check-level-1 {
    @apply text-ops-primary checked:border-ops-primary checked:bg-ops-primary
}

/*===================================*/
/*===== Toast Messages - Fade In ====*/
/*===================================*/

@keyframes slideInFromTop {
    0% {
        transform: translate(-50%, -100%);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

/*=====================*/
/*=====  Spinner  =====*/
/*=====================*/
.spinner.on {
    @apply bg-ops-goldenrod-500 text-center fixed top-1/2 left-1/2 w-[50px] h-[50px] z-[1400] rounded-sm shadow-md;
}

.spinner.off {
    @apply hidden;
}

.spinner > svg,
.spinner > i {
    @apply fill-ops-smoke text-ops-smoke text-4xl relative bottom-1/2 mt-[63%]
}

/*==========================*/
/*=====  Credit Cards  =====*/
/*==========================*/
.cc-blank input:first-child + label,
.visa input:first-child + label,
.mastercard input:first-child + label,
.discover input:first-child + label,
.american-express input:first-child + label
{
    @apply left-10;
}

.cc-blank:before {
    @apply absolute content-[''] left-1 top-1 bottom-1 bg-contain bg-center w-8 bg-[url(../img/cc/blank.svg)]
    pointer-events-none bg-no-repeat;
}
.visa:before {
    @apply absolute content-[''] left-1 top-1 bottom-1 bg-contain bg-center w-8 bg-[url(../img/cc/visa.svg)]
    pointer-events-none bg-no-repeat;
}
.mastercard:before {
    @apply absolute content-[''] left-1 top-1 bottom-1 bg-contain bg-center w-8 bg-[url(../img/cc/mastercard.svg)]
    pointer-events-none bg-no-repeat;
}
.discover:before {
    @apply absolute content-[''] left-1 top-1 bottom-1 bg-contain bg-center w-8 bg-[url(../img/cc/discover.svg)]
    pointer-events-none bg-no-repeat;
}
.american-express:before {
    @apply absolute content-[''] left-1 top-1 bottom-1 bg-contain bg-center w-8 bg-[url(../img/cc/american-express.svg)]
    pointer-events-none bg-no-repeat;
}

/*==========================*/
/*===== Product Filter =====*/
/*==========================*/
input[id^='attribute_']:disabled + label {
    @apply text-ops-gunmetal/60;
}

.top-bar__dropdown {
    transform: translate3d(70px, 39px, 0px)!important;
}

@media (max-width: 600px) {
    .top-bar__dropdown {
        transform: translate3d(2px, 39px, 0px)!important;
    }
}

#productGrid {
    /* Adjust the min-width as per your design */
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}