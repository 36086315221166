/*-------------------------------------------------------------
    Hero
------------------------------------------------------------- */

.nav--home {
    position: absolute;
    z-index: 1;
}

.section__overline {
    border-left: 4px solid #E0AD29;
    padding: 0 1em;
    background-color: #121516;
    color: #F1F1EF;
    width: 297px;
}

.section__title--hero {
    background-image: url('/img/title__bg.svg');
    background-size: cover;
    animation-duration: 1.5s;
}

.subtitle {
    color: #F1F1EF;
    max-width: 480px;
    line-height: 1.6;
}

/* Add the necessary classes for the animation with -hero suffix */
.animate__animated-hero {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animate__fadeInUp-hero {
    animation-name: fadeInUp-hero;
}

/* Define the keyframes for the fadeInUp animation with -hero suffix */
@keyframes fadeInUp-hero {
    from {
        opacity: 0;
        transform: translate3d(0, 40%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@media (max-width: 599.98px) {
    #menuContainer {
        /* Initially off-screen */
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    #menuContainer.active {
        /* Slide into view */
        transform: translateX(0);
    }
}