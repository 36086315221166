@layer components {

    /*====================*/
    /*=====  Buttons  ====*/
    /*====================*/
    .btn-base {
        @apply inline-flex items-center text-center justify-center leading-none select-none uppercase font-saira font-semibold
        shadow-md whitespace-nowrap cursor-pointer
        hover:shadow-lg hover:brightness-[1.2] hover:scale-[1.02]
        focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg
        transition-all duration-150 ease-in-out;
    }

    .btn-xs {
        @apply btn-base px-2 text-xs leading-none h-5;
    }
    .btn-sm {
        @apply btn-base px-3 text-sm leading-none h-7;
    }
    .btn {
        @apply btn-base px-6 text-base h-9;
    }
    .btn-md {
        @apply btn-base px-6 text-lg h-9;
    }
    .btn-lg {
        @apply btn-base px-6 text-xl h-10 text-center font-semibold
    }

    .btn-dd {
        @apply !flex justify-between items-center whitespace-nowrap
        align-middle select-none py-1 pl-2 pr-0
        transition duration-150 ease-in-out
        focus:outline-none focus:ring-0
        motion-reduce:transition-none
    }

    .btn-smoke {
        @apply bg-ops-smoke border-ops-beaver
        hover:bg-ops-smoke/90 hover:shadow-lg
        focus:bg-ops-smoke/90 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-ops-smoke/95 active:shadow-lg
        text-ops-gunmetal;
    }

    .btn-primary {
        @apply btn bg-ops-primary hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }
    .btn-primary-xs {
        @apply btn-xs
        bg-ops-primary hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }
    .btn-primary-sm {
        @apply py-1 px-3 btn-sm
        bg-ops-primary hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }
    .btn-primary-md {
        @apply btn-md
        bg-ops-primary hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }
    .btn-primary-lg {
        @apply btn-lg
        bg-ops-primary hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }

    .btn-gray-sm {
        @apply btn-sm
        bg-ops-placeholder/50 hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal/70;
    }
    .btn-gray-md {
        @apply btn-md
        bg-ops-placeholder/50 hover:shadow-[0_3px_10px_#0000004d]
        text-ops-gunmetal;
    }


    .btn-dark-sm {
        @apply btn-sm
        bg-ops-gunmetal hover:shadow-[0_3px_10px_#0000004d]
        text-ops-smoke;
    }
    .btn-dark {
        @apply btn
        bg-ops-gunmetal hover:shadow-[0_3px_10px_#0000004d]
        text-ops-smoke;
    }
    .btn-dark-md {
        @apply btn-md
        bg-ops-gunmetal hover:shadow-[0_3px_10px_#0000004d]
        text-ops-smoke;
    }
    .btn-dark-lg {
        @apply btn-lg
        bg-ops-gunmetal hover:shadow-[0_3px_10px_#0000004d]
        text-ops-smoke;
    }



    .btn-beaver {
        @apply btn bg-ops-beaver text-ops-smoke
        !shadow-[0_4px_6px_-1px_rgba(0,0,0,0.25),0_2px_4px_-2px_rgba(0,0,0,0.25)]
        hover:bg-ops-gunmetal hover:text-ops-smoke
        focus:bg-ops-beaver/90 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-ops-beaver/95 active:shadow-lg;
    }
    .btn-beaver-outline {
        @apply btn bg-transparent text-ops-beaver font-bold
        border-2 border-ops-beaver
        !shadow-none
        hover:shadow-lg hover:text-ops-beaver/80
        focus:text-ops-beaver/90 focus:shadow-lg focus:outline-none focus:ring-0
        active:text-ops-beaver/90 active:shadow-lg;
    }

    .btn-blue {
        @apply btn bg-blue-600 text-white hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg;
    }
    .btn-red {
        @apply btn bg-red-600 text-white hover:bg-red-700 hover:shadow-lg
        focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-red-800 active:shadow-lg;
    }
    .btn-green {
        @apply btn bg-green-600 text-white hover:bg-green-700 hover:shadow-lg
        focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-green-800 active:shadow-lg;
    }
}