@tailwind base;
@tailwind components;
@tailwind utilities;

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus,[type='select']:focus,[data-te-input-focused]:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(
  --tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #E0AD29;
    --tw-ring-offset-shadow: var(
  --tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(
  --tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #E0AD29!important;
}

*, ::before, ::after {
    --tw-ring-color: #E0AD29!important;

}

.text-primary {
  color: #E0AD29!important;
}

.border-primary {
  border-color: #E0AD29!important;
}

.peer:focus ~ .peer-focus\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(224 173 41 / var(--tw-text-opacity))!important;
}