@layer components {
    .watermark {
        @apply fixed w-full h-[50px] z-50 bg-red-700 text-white flex items-center justify-center;
    }
    .watermark + div {
        padding-top: 50px;
    }
}


/*-------------------------------------------------------------
    Background Fill
------------------------------------------------------------- */

.bg-fill {
	position: relative;
	overflow: hidden;
	width: 100%;
	z-index: 0;
}

.bg-fill--hero {
    padding: 24em 0;
}

.bg-fill .bg-fill__img {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.container {
    width: 100%;
}

@media (max-width: 992px) {
    .container {
        max-width: 100%!important;
        padding-left: 1em;
        padding-right: 1em;
    }
}


@media (min-width: 992px) {
    .container   {
        max-width: 90%
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 90%
    }
}

@media (min-width: 1350px) {
    .container {
        max-width:1200px
    }
}

@media (min-width: 1540px) {
    .container {
        max-width:1200px
    }
}

/* Updated CSS */
  
.cPackage-item {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
}
  

.animate__delay-05 {
    animation-delay: 0.5s;
}

.animate__delay-08 {
    animation-delay: 0.8s;
}

.animate__delay-1s {
    animation-delay: 1s;
}
.animate__duration-1s {
    animation-duration: .95s;
}

/* Missing Images size adjustment */
.image-miss-sm {
    height: 75%;
    width: auto;
}

.long-dashed-border {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23d9d9d9FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;
}