.mega-menu {
    @apply hidden;
}

.hover-mega-menu > a::after {
    content: none;
}
.hover-mega-menu:hover > a::after,
.hover-mega-menu > a:hover::after {
    content: "";
    position: absolute;
    top: 34px; /* Adjust this value based on the size of the triangle */
    left: 50%;
    transform: translateX(-10px);
    border-left: 10px solid transparent; /* Half the width of the triangle */
    border-right: 10px solid transparent; /* Half the width of the triangle */
    border-bottom: 10px solid white; /* Color and height of the triangle */
}

.hover-mega-menu:hover .mega-menu {
    @apply block;
}
.hover-mega-menu:hover > a {
    @apply text-ops-primary;
}