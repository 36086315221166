/*
 |--------------------------------------------------------------------------
 | TailwindCSS Directives
 |--------------------------------------------------------------------------
 |
 | Import TailwindCSS directives and swipe out at build-time with all of
 | the styles it generates based on your configured design system.
 |
 | Please check this link for more details:
 | https://tailwindcss.com/docs/installation#include-tailwind-in-your-css
 |
 */
 @import "./_tailwind.css";

/*
   |--------------------------------------------------------------------------
   | Fonts
   |--------------------------------------------------------------------------
   |
   | Import all fonts used in the template, the font configuration can be
   | seen in "tailwind.config.js".
   |
   | Please check this link for more details:
   | https://tailwindcss.com/docs/theme
   |
   */
   @import "./_roboto.css";

   /*
   
   /*
   |--------------------------------------------------------------------------
   | Animations
   |--------------------------------------------------------------------------
   |
   | Import All animations from Animate.style library 
   |
   | Please check this link for more details:
   | https://animate.style/
   |
   */
   @import "./_animate.min.css";

   /*
   |--------------------------------------------------------------------------
   | Components
   |--------------------------------------------------------------------------
   |
   | Import CSS components.
   |
   */
@import "./components/_global.css";
@import "./components/_hero.css";
@import "./components/_others.css";
@import "./components/_form-animate.css";
@import "./components/_mega-menu.css";
@import "./components/_buttons.css";