

.form-animate {
    position: relative;

    input[type="date"],
    input[type="email"],
    input[type="file"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    textarea {
        @apply outline-none z-10 w-full border border-ops-form-border
            focus:ring-0
            placeholder:opacity-0;
    }

    .select {
        &.active {
            .arrow {
                @apply rotate-180;
            }

            .listbox {
                @apply opacity-100 visible scale-y-100 origin-top;
            }
        }
        .select-button {
            @apply flex items-center w-full bg-white text-base py-2 px-4 border border-ops-form-border cursor-pointer
                outline-none z-10
                focus:ring-0
                placeholder:opacity-0;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
            background-position: right 0.5rem center;
            background-repeat: no-repeat;
            background-size: 1.5em 1.5em;
            padding-right: 2.5rem;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;

            [data-animate-select-label] {
                @apply text-left;
            }
        }

        .listbox {
            @apply absolute list-none w-full shadow bg-white border border-ops-form-border py-1 px-1 mt-1 max-h-72
            overflow-y-auto transition-all duration-200 ease-out z-[1400]
            scale-y-0 opacity-0 invisible origin-top;

            &:focus-within {
                @apply shadow-lg;
            }

            &::-webkit-scrollbar {
                @apply w-1;
            }
            &::-webkit-scrollbar-track {
                @apply bg-ops-smoke rounded-xl;
            }
            &::-webkit-scrollbar-thumb {
                @apply bg-[#ccc] rounded-xl;
            }

            .list-option {
                @apply relative cursor-pointer flex gap-4 items-center pl-4 pr-1 hover:bg-ops-gunmetal/30;

                .li-input {
                    &:checked {
                        ~ label {
                            /*@apply bg-ops-gunmetal/30;*/
                            /* Add a checkmark */
                        }
                    }
                    &:focus {
                        ~ label {
                            @apply bg-ops-gunmetal/40;
                        }
                    }

                    &[type=radio] {
                        @apply absolute left-0 opacity-0;
                    }
                }

                .li-label {
                    @apply w-full cursor-pointer;
                }
            }
        }
    }

    label.animate {
        @apply absolute px-1 py-0 leading-4 z-10 translate-y-[0.85rem]
        transition-all duration-200 ease-out top-0
        pointer-events-none font-roboto font-[400] left-3 mb-0 max-w-[90%] origin-[0_0] truncate
        text-ops-gunmetal/70
        motion-reduce:transition-none;

        &.has-value {
            @apply -translate-y-[0.4rem] scale-[0.8];
        }
    }

    &.small {
        input[type="date"],
        input[type="email"],
        input[type="file"],
        input[type="number"],
        input[type="password"],
        input[type="tel"],
        input[type="text"],
        input[type="time"] {
            @apply px-2 py-1;
        }

        label.animate {
            @apply leading-4 translate-y-[0.55rem]
            origin-[0_0];

            &.has-value {
                @apply -translate-y-[0.31rem];
            }
        }
    }
}

/****** Browser auto-fill ********/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    @apply shadow-[0_0_0_30px_white_inset] !important;
}